<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size" v-if="!theme.is_mobile">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="请输入区域名称关键词" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.level" placeholder="类型" filterable clearable>
						<el-option v-for="(level, l) in levels" :key="l" :label="level" :value="l"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getWechat({ ...params, page: 1})">查询</el-button>
					<el-button type="primary" @click="create_drawer = true">新增地区</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="regions" :size="theme.size">
				<el-table-column prop="id" label="ID" width="100"></el-table-column>
				<el-table-column prop="level_name" label="类型" width="100"></el-table-column>
				<el-table-column prop="name" label="名称"></el-table-column>
				<el-table-column prop="parent.name" label="上级"></el-table-column>
				<el-table-column prop="location" label="中心坐标"></el-table-column>
				<el-table-column prop="updated_at" label="更新时间" width="180" v-if="theme.width >= 1080"></el-table-column>
				<el-table-column prop="created_at" label="创建时间" width="180" v-if="theme.width >= 1080"></el-table-column>
				<el-table-column prop="name" label="操作" width="100">
					<template slot-scope="scope">
						<el-button @click="onDelete(scope.row)" type="text" :size="theme.size">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getWechat({ ...params, page: 1, perPage: v}) }"
				@current-change="(v) => { getWechat({ ...params, page: v}) }"
				background>
			</el-pagination>
		</main-table>
		<el-drawer title="新增地区" :visible.sync="create_drawer">
			<div style="padding: 0 15px;">
				<el-form ref="create_form" label-width="80px" :model="region" :rules="region_rules" :size="theme.size" @closed="resetForm('create_form')">
					<el-form-item label="上级" prop="regions">
						<el-cascader v-model="region.regions" :props="props"></el-cascader>
					</el-form-item>
					<el-form-item label="名称" prop="name">
						<el-input v-model="region.name" placeholder="请输入地区名称"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('create_form')">立即创建</el-button>
						<el-button @click="resetForm('create_form')">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
	</div>
</template>

<style>
	.el-cascader {
		width: 100%;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			resetForm (ref) {
				this.create_drawer = false;
				this.$refs[ref].resetFields();
				this.region = {};
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					this.submitRegion(this.region);
				})
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】区域吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_REGIONS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getRegions(this.params);
						}
					});
				})
			},
			async submitRegion (data) {
				const res = await this.$http.post(this.$api.URI_REGIONS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.create_drawer) {
							this.create_drawer = false;
							this.getRegions(this.params);
						}
					}
				})
			},
			async getWechat (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_REGIONS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.regions = result.data;
				this.levels = result.levels;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				regions: [],
				levels: [],
				create_drawer: false,
				region: {},
				props: {
					lazy: true,
					value: 'id',
					label: 'name',
					checkStrictly: true,
					lazyLoad: async (node, resolve) => {
						const { value } = node;
						const res = await this.$http.get(this.$api.URI_REGIONS, {params: {action: 'region', region: value?value:0}, headers: {loading: true}});
						resolve(res.data.result);
					}
				},
				params: {
					perPage: 10,
				},
				region_rules: {
					name: [
						{ required: true, message: '请输入区域名称', trigger: 'blur' }
					],
					regions: [
						{ required: true, type: 'array', message: '请选择上级地区', trigger: 'change' }
					],
				}
			}
		},
		created () {
			this.getWechat(this.params, true)
		}
	};
</script>